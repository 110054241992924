/*
  MAIN JS
------------------------------
*/
$(function () {
  // ページ先頭へスクロール
  $(".back-to-top").on("click", function (event) {
    event.preventDefault();
    $("html, body").animate({ scrollTop: 0 }, "slow");
  });

  // メニュー表示の切り替え（タブレット以下）
  $(".menu-toggle").on("click", function () {
    if (window.matchMedia("(min-width: 767px)").matches) {
      $(".nav-right").toggleClass("is-visible");
    } else {
      $(".nav-wrap").toggleClass("is-visible");
      $("body").css("overflow", $(".nav-wrap").hasClass("is-visible") ? "hidden" : "auto");
    }
    $(this).toggleClass("active");
  });

  // SP表示: メニューをラップ
  function wrapNav() {
    if ($(window).width() <= 767) {
      if (!$(".nav-wrap").length) {
        $(".nav-left, .nav-right").wrapAll("<div class='nav-wrap'></div>");
      }
    } else if ($(".nav-wrap").length) {
      $(".nav-left, .nav-right").unwrap();
    }
  }

  wrapNav();
  $(window).on("resize", wrapNav);

  // SP表示: アコーディオン設定（メニュー専用）
  function handleAccordion() {
    if ($(window).width() <= 767) {
      if (!$(".parent").data("accordion-initialized")) {
        $(".parent").off("click").on("click", function () {
          const $menuMega = $(this).find(".menu-mega");
          const $parent = $(this);
          $menuMega.stop().slideToggle({
            complete: function () {
              if ($menuMega.is(":visible")) {
                $parent.addClass("open");
              } else {
                $parent.removeClass("open");
              }
            },
          });
        });
        $(".parent").data("accordion-initialized", true);
      }
    } else {
      $(".parent").off("click").removeData("accordion-initialized").removeClass("open");
      $(".menu-mega").removeAttr("style");
    }
  }
  
  let resizeTimer;
  $(window).on("resize", function () {
    clearTimeout(resizeTimer);
    resizeTimer = setTimeout(() => {
      handleAccordion();
    }, 200);
  });
  
  handleAccordion();

  // アコーディオン（汎用/初期状態: 閉）
  // $(".accordion-trigger").on("click", function () {
  //   $(this).next(".accordion-content").slideToggle();
  //   $(this).toggleClass("open");
  // });

  // アコーディオン（一時的/初期状態: 開）
  // 初期状態で展開する
  $(".accordion-trigger").each(function () {
    const $content = $(this).next(".accordion-content");
    $content.show(); // 初期表示を展開状態に
    $(this).addClass("open"); // 初期状態でトリガーに "open" クラスを付与
  });

  // クリックイベントで開閉を切り替える
  $(".accordion-trigger").on("click", function () {
    $(this).next(".accordion-content").slideToggle();
    $(this).toggleClass("open");
  });
});
